import styled from "styled-components";
import { Link } from "gatsby";
import { TABLET_BREAKPOINT } from "../constants/breakpoints";

export const NavItem = styled(Link)`
  box-sizing: border-box;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: var(--primary-color);
  text-decoration: none;
  position: relative;
  display: inline-flex;
  height: 51px;
  align-items: center;

  @media (min-width: ${TABLET_BREAKPOINT}) {
    height: 98px;
    font-size: 24px;
    line-height: 29px;
  }

  // Outline is spread on :after pseudo-element
  // so replacing it with box-shadow to keep it
  // accessible for keyboard navigation
  outline: none;

  &:focus-visible {
    box-shadow: 0 0 0 2px rgb(0, 95, 204);
  }

  &:focus,
  &:hover {
    color: #88959d;
  }

  &:after {
    position: absolute;
    content: "";
    width: 100%;
    height: 4px;
    background: transparent;
    // Height of the border bottom
    bottom: -1px;
    left: 0;
  }

  &.active {
    color: var(--primary-color);
    font-weight: 700;

    &:after {
      background: var(--primary-color);
    }
  }
`;
