import React from "react";
import instagramImg from "../images/social/instagram.svg";
import youtubeImg from "../images/social/youtube.svg";
import telegramImg from "../images/social/telegram.svg";
import facebookImg from "../images/social/facebook.svg";
import styled from "styled-components";
import { DESKTOP_BREAKPOINT } from "../constants/breakpoints";
import { OutboundLink } from "gatsby-plugin-google-gtag";

const SocialImg = styled("img")`
  width: 24px;

  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    width: 38px;
  }
`;

const SocialContainer = styled("ul")`
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;

  a:hover,
  a:focus {
    filter: brightness(80%);
  }
`;

const Social = () => {
  return (
    <SocialContainer>
      <li>
        <OutboundLink href="https://instagram.com/JSCompetencyCenter" target="_blank" title="JS CC on Instagram">
          <SocialImg src={instagramImg} alt="" />
        </OutboundLink>
      </li>
      <li>
        <OutboundLink href="https://youtube.com/@JavaScriptCompetencyCenter" target="_blank" title="JS CC on YouTube">
          <SocialImg src={youtubeImg} alt="" />
        </OutboundLink>
      </li>
      <li>
        <OutboundLink href="https://facebook.com/JSCompetencyCenter" target="_blank" title="JS CC on Facebook">
          <SocialImg src={facebookImg} alt="" />
        </OutboundLink>
      </li>
      <li>
        <OutboundLink href="https://t.me/JSCompetencyCenter" target="_blank" title="JS CC on Telegram">
          <SocialImg src={telegramImg} alt="" />
        </OutboundLink>
      </li>
      {/*<li>*/}
      {/*  <a*/}
      {/*    href="https://discord.gg/qPZPy5yUaG"*/}
      {/*    target="_blank"*/}
      {/*    title="JS CC on Discord"*/}
      {/*  >*/}
      {/*    <SocialImg src={discordImg} alt="" />*/}
      {/*  </a>*/}
      {/*</li>*/}
    </SocialContainer>
  );
};

export default Social;
