import React from "react";
import styled from "styled-components";
import Social from "./Social";
import footerBg from "../images/footer.svg";
import footerMobileBg from "../images/footer-mobile.svg";
import { DESKTOP_BREAKPOINT } from "../constants/breakpoints";

const FooterContainer = styled("footer")`
  height: 86px;
  // By some reason on specific screen sizes on iPhones
  // there are white borders to the right and left from the image
  // Setting bg color makes it "invisible"
  background: var(--primary-color) url(${footerMobileBg}) no-repeat;
  background-size: cover;
  margin: 1px auto 0;

  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    height: 206px;
    background-image: url(${footerBg});
  }
`;

const FooterParagraph = styled("p")`
  padding-top: 22px;
  font-weight: 700;
  font-size: 16px;
  line-height: 12px;
  text-align: center;
  color: white;
  margin: 0 0 12px;
  position: relative;

  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    padding-top: 55px;
    font-size: 24px;
    line-height: 23px;
    margin-bottom: 41px;

    &:after {
      display: block;
      position: absolute;
      left: 50%;
      bottom: -18px;
      transform: translateX(-50%);
      content: "";
      width: 40px;
      height: 2px;
      border-radius: 2px;
      background: white;
    }
  }
`;

const Footer = () => {
  return (
    <FooterContainer>
      <FooterParagraph>Follow us on our social networks</FooterParagraph>
      <Social />
    </FooterContainer>
  );
};

export default Footer;
