import React, { FC, PropsWithChildren } from "react";
import Nav from "./Nav";
import styled from "styled-components";
import "../global.scss";
import Hero from "./Hero";
import Footer from "./Footer";

const Wrapper = styled("main")`
  box-sizing: border-box;
  max-width: 1170px;
  margin: 0 auto;
`;

const Layout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div>
      <header>
        <Hero />
        <Wrapper as="div">
          <Nav />
        </Wrapper>
      </header>
      <Wrapper>{children}</Wrapper>
      <Footer />
    </div>
  );
};

export default Layout;
