import React from "react";
import styled from "styled-components";
import { NavItem } from "./NavItem";
import { TABLET_BREAKPOINT } from "../constants/breakpoints";

const NavContainer = styled("ul")`
  box-sizing: border-box;
  display: flex;
  gap: 36px;
  list-style: none;
  padding: 0 45px;
  margin: 0;
  justify-content: center;

  @media (min-width: ${TABLET_BREAKPOINT}) {
    padding: 0;
  }
`;

const NavBottomLine = styled("div")`
  background: #a3b1bd;
  border-radius: 2px;
  height: 1px;
`;

const Nav = () => {
  return (
    <nav>
      <NavContainer>
        <li>
          <NavItem activeClassName="active" to="/events">
            Events
          </NavItem>
        </li>
        <li>
          <NavItem activeClassName="active" to="/articles">
            Articles
          </NavItem>
        </li>
        <li>
          <NavItem activeClassName="active" to="/digests">
            Digest
          </NavItem>
        </li>
      </NavContainer>
      <NavBottomLine />
    </nav>
  );
};

export default Nav;
