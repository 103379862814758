import styled from "styled-components";
import mobileHeaderImg from "../images/header-mobile.svg";
import { DESKTOP_BREAKPOINT } from "../constants/breakpoints";
import headerImg from "../images/header.svg";
import logoImg from "../images/logo.svg";
import Social from "./Social";
import React from "react";
import { StaticImage } from "gatsby-plugin-image";

const HeroContainer = styled("div")`
  height: 195px;
  background-color: var(--primary-color);
  background-image: url(${mobileHeaderImg});
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;

  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    height: 400px;
    background-image: url(${headerImg});
  }
`;

const RacoonImgContainer = styled("div")`
  position: absolute;
  left: calc(50% + 112px);
  top: 36px;
  width: 113px;

  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    top: 34px;
    left: calc(50% + 335px);
    width: initial;
  }
`;

const Logo = styled("img")`
  margin-bottom: 20px;
  height: 28px;

  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    height: initial;
    margin-bottom: 44px;
  }
`;

const HeroParagraph = styled("p")`
  text-align: center;
  font-size: 16px;
  line-height: 19px;
  letter-spacing: 0.02em;
  color: #ffffff;
  margin: 0 auto 20px;
  max-width: 237px;

  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    font-size: 24px;
    line-height: 29px;
    margin: 0 auto 56px;
    max-width: 500px;
  }
`;

const HeroContent = styled("div")`
  max-width: 891px;
  margin: 0 auto;
  justify-self: center;
  padding-top: 31px;
  text-align: center;
  z-index: 1;
  position: relative;

  @media (min-width: ${DESKTOP_BREAKPOINT}) {
    padding-top: 100px;
  }
`;

const Hero = () => {
  return (
    <HeroContainer>
      <RacoonImgContainer>
        <StaticImage src="../images/Raccoon.png" alt="" width={340} placeholder="none" />
      </RacoonImgContainer>
      <HeroContent>
        <Logo src={logoImg} alt="" width="282" height="59" />
        <HeroParagraph>
          <span className="sr-only">JavaScript Competency Center</span>Your source for online education in web
          development & engineering
        </HeroParagraph>
        <Social />
      </HeroContent>
    </HeroContainer>
  );
};

export default Hero;
